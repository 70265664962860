.nav-treeview {
  margin-left: 20px;
}

.loader {
  margin: auto;
  width: 80px;
  /* margin-top: 100px; */
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid orange;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.image-container {
  position: relative;
}

.nav-item {
  cursor: pointer;
}

.image-container .btn-trash {
  position: absolute;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  right: -70px;
  top: 22px;
}

.image-container .btn-edit {
  position: absolute;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  right: -30px;
  top: 22px;
}

.card-filter {
  float: left;
  margin: 0 10px;
}

.issue-btn {
  margin-top: 32px;
}

.image-container {
  width: 150px;
  margin: 10px;
  display: inline;
}

.accordion-header button {
  width: 100%;
  text-align: left;
  border-color: lightgray;
  background-color: #ffc107;
  color: white;
  font-size: 20px;
}

.accordion-item {
  border: lightgray 1px solid;
  padding: 10px;
}

@media print {
  .list-print-area {
    margin-top: 100px;
  }
}
